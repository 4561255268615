import Auth from '@/components/auth';
import Heading from '@/components/heading';
import Hidden from '@/components/hidden';
import Name from '@/components/name';
import TimeZone from '@/components/time-zone';
import {
  Authenticator,
  CheckboxField,
  Divider,
  Flex,
  useTheme,
  View,
} from '@aws-amplify/ui-react';
import { FunctionComponent, ReactNode, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface SignUpAttributes {
  userId: string;
}

const SignUp: FunctionComponent<{
  fields: ReactNode;
}> = ({ fields }) => {
  const { tokens } = useTheme();
  return (
    <>
      <Heading>Create a login</Heading>
      <Authenticator.SignUp.FormFields />
      <Divider
        marginTop={tokens.space.medium}
        marginBottom={tokens.space.small}
      />
      {fields}
      <Divider
        marginTop={tokens.space.medium}
        marginBottom={tokens.space.medium}
      />
      <View marginBottom={tokens.space.large}>
        <Flex justifyContent="center">
          <CheckboxField label="" name="agree" value="yes" />
          <View style={{ userSelect: 'none' }}>
            I agree to the{' '}
            <a
              href="https://www.honeag.com/documents"
              target="_blank"
              rel="noreferrer"
            >
              terms
            </a>{' '}
            and{' '}
            <a
              href="https://www.honeag.com/documents"
              target="_blank"
              rel="noreferrer"
            >
              privacy policy
            </a>
          </View>
        </Flex>
      </View>
    </>
  );
};

const ForcePassword: FunctionComponent<{
  fields: ReactNode;
}> = ({ fields }) => {
  const { tokens } = useTheme();
  return (
    <>
      <Heading>Set your password</Heading>
      <Authenticator.ForceNewPassword.FormFields />
      <Divider
        marginTop={tokens.space.medium}
        marginBottom={tokens.space.small}
      />
      <View marginBottom={tokens.space.large}>{fields}</View>
    </>
  );
};

const UserPage: FunctionComponent = () => {
  const userIdKey = 'custom:user_id';

  const { tokens } = useTheme();
  const [signUp, setSignUp] = useState<SignUpAttributes>({
    userId: uuidv4(),
  });

  // Define the fields needed to capture user attributes
  const fields = (
    <View>
      <View marginBottom={tokens.space.xxs}>
        <Heading>Tell us about yourself</Heading>
      </View>
      <View>
        <Hidden name={userIdKey} value={signUp.userId} />
        <TimeZone />
        <Name />
      </View>
    </View>
  );

  return (
    <>
      <Auth
        logo={{
          color: 'var(--amplify-colors-brand-primary-90)',
          label: 'Hone',
        }}
        signUp={<SignUp fields={fields} />}
        forcePassword={<ForcePassword fields={fields} />}
        onSignIn={(response) => {
          // For admin created accounts a new password is forced
          // A user id is also set, which needs to be maintained across the sign up
          if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
            const {
              challengeParam: { userAttributes },
            } = response;

            // Apply any existing sign up attributes
            const userId = userAttributes[userIdKey];

            if (userId) {
              setSignUp({
                userId,
              });
            }
          }
        }}
        onValidateSignUp={(formData) => {
          if (formData.agree !== 'yes') {
            return {
              agree: 'no',
            };
          }
        }}
        onCreateClaims={(idToken, claims) => ({
          ...claims,
          userId: idToken[userIdKey],
        })}
      />
    </>
  );
};

export default UserPage;
