import { useTheme, View } from '@aws-amplify/ui-react';
import { FunctionComponent, ReactNode } from 'react';

const Footer: FunctionComponent<{
  authName: string;
  custom?: ReactNode;
}> = ({ authName, custom }) => {
  const { tokens } = useTheme();
  const authNameLower = authName.toLowerCase();
  const version = process.env.GATSBY_VERSION || `0.0.0-${authNameLower}`;
  const styles = {
    color: tokens.colors.white,
    backgroundColor: 'transparent',
  };

  // Color the version based on the auth name
  switch (authNameLower) {
    case 'dev':
      styles.backgroundColor = '#1890ff';
      break;
    case 'staging':
      styles.backgroundColor = '#722ed1';
      break;
    case 'prod':
    case 'internal':
      styles.color = tokens.colors.overlay[40];
      break;
    default:
      styles.backgroundColor = '#13c2c2';
      break;
  }

  return (
    <View
      style={{ userSelect: 'none' }}
      fontSize={tokens.fontSizes.small}
      padding={tokens.space.large}
      textAlign="center"
    >
      {custom && <View>{custom}</View>}
      <View
        color={styles.color}
        backgroundColor={styles.backgroundColor}
        padding={tokens.space.xxxs}
      >
        Version: {version}
      </View>
    </View>
  );
};

export default Footer;
